import React from "react";
import {
    MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import ACDJNavbar from "../components/ACDJNavbar";
import ACDJFooter from "../components/ACDJFooter";
import qrcodeImg from "../assets/img/qrcode-vcard-actusoft.png";
import MapWithAMarker from "../components/gmap";

const ContactPage = () => {
    return (
        <div>
            <header>
                <ACDJNavbar homepage={false}/>
            </header>
            <main style={{ marginTop: '5rem' }}>
                <MDBContainer>
                    <h2 className="text-center mb-3">Contact</h2>
                    <section className="mt-5 wow fadeIn">
                        <MDBRow>
                            <MDBCol md="6" className="mb-4">
                                <p>Mes interventions peuvent se réaliser en régie ou en forfait, dans toute l'<strong>île de france</strong>.</p>
                                <p>Vous pouvez me contacter à l'adresse suivante :</p>
                                <strong>Société ACTUSOFT<br />
                                Christophe NIGAUD<br />
                                36, rue des Glaises<br />
                                    78700 Conflans Sainte Honorine</strong><br /><br />
                                <MDBIcon icon="envelope" />: <a href="mailto:christophe.nigaud@actusoft.fr">christophe.nigaud@actusoft.fr</a><br />
                                <MDBIcon icon="mobile" />: +33 (0)6.76.59.90.21<br />
                                <img src={qrcodeImg} alt={"2d code bar"}/>
                            </MDBCol>
                            <MDBCol md="6" className="mb-4">
                                <div id="map-container" className="z-depth-1-half map-container mb-5"
                                     style={{height: "240px"}}>
                                    <MapWithAMarker
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBGlXoAwIsSlO_Tuwtcfnj1UbefTwRcsWQ&v=3.exp&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{ height: `80%` }} />}
                                        containerElement={<div style={{ height: `300px` }} />}
                                        mapElement={<div style={{ height: `80%` }} />}
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </section>
                </MDBContainer>
            </main>

            <footer>
                <ACDJFooter/>
            </footer>
        </div>
    );
}

export default ContactPage;
