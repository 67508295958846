import React from "react";
import SectionImage from '../assets/img/20200605_143457.jpg';
import {MDBCol, MDBRow, MDBAnimation} from "mdbreact";

const HomePageSection3 = () => {
    return (
        <section className="mt-5 wow fadeIn">
            <MDBRow>
                <MDBCol md="6" className="mb-4">
                    <MDBAnimation reveal type='fadeIn'>
                        <img src={SectionImage} className="img-fluid z-depth-1-half" alt=""/>
                    </MDBAnimation>
                </MDBCol>
                <MDBCol md="6" className="mb-4">
                    <h3 className="text-center h3 mb-3">à votre disposition...</h3>
                    <p>Si vous avez la perspective d'un projet commun, j'aurai plaisir d'échanger avec vous sur le sujet.</p>
                    <p>Vous trouverez mon cv sur la page Mission.</p>
                    <p>A très vite ! :-)</p>
                </MDBCol>
            </MDBRow>
        </section>
    )
}

export default HomePageSection3;
