import React, {Component} from 'react';
import {BrowserRouter as Router } from 'react-router-dom';
import Routes from "../../navigations/Routes";
import { AuthContext } from "../../services/Auth";

class Index extends Component {
  state = {
    collapseID: ''
  };

  render() {
    return (
        <AuthContext.Provider value={false}>
            <Router>
                <Routes/>
            </Router>
        </AuthContext.Provider>
    );
  }
}

export default Index;
