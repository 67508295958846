import React from "react";
import SectionImage from '../assets/img/equipe.png';
import {MDBCol, MDBRow, MDBAnimation} from "mdbreact";

const HomePageSection1 = () => {
    return (
        <section className="mt-5 wow fadeIn">
            <MDBRow>
                <MDBCol md="6" className="mb-4">
                    <MDBAnimation reveal type='fadeIn'>
                        <img src={SectionImage} className="img-fluid z-depth-1-half" alt=""/>
                    </MDBAnimation>
                </MDBCol>
                <div className="col-md-6 mb-4">
                    <h3 className="text-center h3 mb-3">Besoin d'une resource ponctuelle ?</h3>
                    <p>Je peux venir renforcer votre équipe de Dev le temps de pouvoir répondre
                        aux nouveaux besoins exprimés par le PO ou le métier.</p>
                    <p>Un nouveau projet et personne sous la main ?!  Je peux mener à bien ce projet pour vous
                        et vous apporter aussi toute la visibilité nécessaire aux décisions à prendre.</p>
                    <p>Ayant répondu aux besoins de structure différentes, je serais à même de pouvoir m'adapter au rôle que
                        vous me donnerez, dans un esprit pragmatique et constructif.</p>
                </div>
            </MDBRow>
        </section>
    )
}

export default HomePageSection1;
