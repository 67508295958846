import React from "react";
import SectionImage from '../assets/img/acp.jpg';
import {MDBCol, MDBRow, MDBAnimation} from "mdbreact";

const HomePageSection2 = () => {
    return (
        <section>
            <MDBRow className="mb-4 wow fadeIn">
                <MDBCol lg="6" md="12" className="px-4">
                    <h3 className="text-center h3 mb-3">Polyvalence</h3>
                    <div className="row">
                        <div className="col-1 mr-3">
                            <i className="fas fa-atom fa-2x brown-text" />
                        </div>
                        <div className="col-10">
                            <h5 className="feature-title"><strong>Back</strong></h5>
                            <p>Serveurs (Wildfly, Tomcat, Websphere, Apache, ...), Database (Oracle, MySQL, MongoDB, Postgres, ...), SQL, PL/SQL, JQuery, JPA,
                                Hibernate (dont Search), api REST (), JSON (jackson), XML (JAXB), Lucene, Sécurité (OAuth, JAAS, Spring Security, ...)</p>
                            <p>En projet perso actuellement : GraphQL</p>
                        </div>
                    </div>
                    <div style={{height:"30px"}} />
                    <div className="row">
                        <div className="col-1 mr-3">
                            <i className="fas fa-tablet-alt fa-2x pink-text" />
                        </div>
                        <div className="col-10">
                            <h5 className="feature-title"><strong>Front</strong></h5>
                            <p>HTML 5, CSS 3 (medias, SASS, ...), JSF (Primefaces, Bootsfaces), Javascript (JQuery), Bootstrap, Struts.</p>
                            <p>En projet perso actuellement : React-JS (MDB) <strong>[Ce site]</strong>, React-Native/Redux</p>
                        </div>
                    </div>

                    <div style={{height:"30px"}} />

                    <div className="row">
                        <div className="col-1 mr-3">
                            <i className="fas fa-tools fa-2x green-text" />
                        </div>
                        <div className="col-10">
                            <h5 className="feature-title"><strong>Architecture et outils</strong></h5>
                            <p>Microservice (Thorntail), CQRS/Event Soucing, maven, git, svn</p>
                            <p>En projet perso actuellement : Docker (io.fabric8), Kafka, Elasticsearch</p>
                        </div>
                    </div>
                </MDBCol>

                <div className="col-lg-6 col-md-12">
                    <MDBAnimation reveal type='fadeIn'>
                        <img src={SectionImage} className="img-fluid z-depth-1-half" alt="" />
                    </MDBAnimation>
                </div>
            </MDBRow>
        </section>
    )
}

export default HomePageSection2;
