import React from 'react';
import {Route, Switch} from 'react-router-dom';

import HomePage from '../pages/HomePage';
import MissionsPage from "../pages/MissionsPage";
import ContactPage from "../pages/ContactPage";
import ReleasePage from "../pages/ReleasePage";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/missions' component={MissionsPage} />
        <Route exact path='/contact' component={ContactPage} />
        <Route exact path='/release' component={ReleasePage} />

        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
