import React from "react";
import {
    MDBCol, MDBContainer,
    MDBRow,
} from 'mdbreact';
import ACDJNavbar from "../components/ACDJNavbar";
import ACDJFooter from "../components/ACDJFooter";
import '../assets/css/MentionsStyle.css';

const ReleasePage = () => {
    return (
        <div>
            <header>
                <ACDJNavbar homepage={false}/>
            </header>
            <main style={{ marginTop: '5rem' }}>
                <MDBContainer>
                    <section className="mt-5 wow fadeIn">
                        <MDBRow>
                            <MDBCol md="8" className="mb-4 offset-md-2">
                                <h2>VERSIONS ET ROADMAP DU SITE</h2>
                                <h3>Roadmap</h3>
                                <ol>
                                    <li style={{textDecoration: "line-through"}}>Technique: Migration du site JSF -> React.js (MDB)</li>
                                    <li>Technique: Migration jdk 8 -> jdk 11</li>
                                    <li><span>Technique: Upgrade Wildfly (10.1.0 -> 20.0.0)</span></li>
                                    <li>Technique: Déploiement par conteneurisation (Docker)</li>
                                    <li>Technique: Migration en microservice (Rest+Thorntail+Microprofile)</li>
                                    <ul>
                                        <li>Architecture CQRS / Event sourcing <span style={{color: "red"}}>(30%)</span></li>
                                    </ul>
                                </ol>
                                <h3>Suivi des versions</h3>
                                <h4>v2.0 - 15/06/2020</h4>
                                <ul>
                                    <li>Réécriture complète du site en React js</li>
                                    <li><strong>API :</strong> React JS (MDB 4.26.1), template Bootstrap 4</li>
                                    <li><strong>Plateforme :</strong> Wildfly 19, Intellij, app dockerisée, Maven 3 (frontend-maven-plugin), Git</li>
                                </ul>
                                <h4>v1.0 - 01/06/2013</h4>
                                <ul>
                                    <li>Réalisation du site : </li>
                                    <li><strong>API :</strong> JSF 2.1 (primefaces 3.4.2), JPA 2.0 (Hibernate), template par Facelets</li>
                                    <li><strong>Plateforme :</strong> Glassfish 3.1.2.2 migré sur Wildfly 9.0.2 (JBoss), Mysql 5.0, Eclipse Luna, Maven, Subversion</li>
                                </ul>
                            </MDBCol>
                        </MDBRow>
                    </section>
                </MDBContainer>
            </main>
            <footer>
                <ACDJFooter/>
            </footer>
        </div>
    );
}

export default ReleasePage;
