import React from "react";
import {MDBFooter} from "mdbreact";
import './style.css';

const ACDJFooter = () => {
    return (
        <MDBFooter>
            <div className="d-flex justify-content-around align-items-center">
                <div className="p-2 bd-highlight">Actusoft à votre service depuis <strong>2006</strong></div>
                <div className="p-2 bd-highlight">
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="https://www.linkedin.com/in/christophe-nigaud-3592715a" target="_blank" rel="noopener noreferrer"><i
                            className="fab fa-linkedin fa-2x"/></a></li>
                        <li className="list-inline-item"><a href="/release"><i className="fas fa-code-branch fa-2x"/></a></li>
                    </ul>
                </div>
            </div>
        </MDBFooter>
    )
}

export default ACDJFooter;
