import React from "react";
import {
    MDBAnimation,
    MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol,
    MDBContainer, MDBRow,
} from 'mdbreact';
import ACDJNavbar from "../components/ACDJNavbar";
import ACDJFooter from "../components/ACDJFooter";
import imgAg2rlm from "../assets/img/missions/ag2rlm.gif";
import imgPSA from "../assets/img/missions/Banque_PSA_Finance.png";
import imgBouygues from "../assets/img/missions/bouygues.png";
import imgCapgemini from "../assets/img/missions/capgemini.png";
import imgCdn from "../assets/img/missions/cdn.png";
import imgConges_Spec from "../assets/img/missions/conges_spectacles.png";
import imgCredipar from "../assets/img/missions/credipar.gif";
import imgIbm from "../assets/img/missions/ibm.png";
import imgIngdirect from "../assets/img/missions/ingdirect.png";
import imgLaposte from "../assets/img/missions/laposte.png";
import imgModis from "../assets/img/missions/modis-logo.png";
import imgRodrigue from "../assets/img/missions/rodrigue.png";
import imgSanofi from "../assets/img/missions/sanofi.png";
import imgServier from "../assets/img/missions/servier.jpg";
import imgTraveldoo from "../assets/img/missions/traveldoo.png";
import cvPdf from '../assets/CV_Christophe_NIGAUD.pdf';

class MissionsPage extends React.Component {
  render() {
    return (
        <div>
            <header>
                <ACDJNavbar homepage={false} />
            </header>
            <main class="mt-5 pt-5">
                <MDBContainer>
                    <section class="card wow fadeIn aqua-gradient">
                        <div className="card-body text-white text-center py-4 px-5 my-5">
                            <h1 className="mb-4">
                                <strong>Mes missions</strong>
                            </h1>
                            <p>
                                <strong>Ils m'ont fait confiance pour répondre à leur besoin et je les en remercie !</strong>
                            </p>
                        </div>
                    </section>
                    <hr className="my-5" />
                    <section className="text-center">
                        <MDBRow className="wow fadeIn">
                            <MDBCol lg-4 md-12 >
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBAnimation reveal type='fadeIn'>
                                        <MDBCardImage src={imgPSA} className="mx-auto" waves />
                                    </MDBAnimation>
                                    <MDBCardBody>
                                        <MDBCardTitle>Banque PSA Finance</MDBCardTitle>
                                        <MDBCardText>
                                            Responsable Technique
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard className="text-center">
                                    <MDBCardImage src={imgTraveldoo} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Traveldoo</MDBCardTitle>
                                        <MDBCardText>
                                            Dévelopeur Senior
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard>
                                    <MDBCardImage src={imgRodrigue} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Rodrigue Solutions</MDBCardTitle>
                                        <MDBCardText>
                                            Concepteur/Développeur
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                        <MDBRow className="wow fadeIn">
                            <MDBCol lg-4 md-12>
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBCardImage src={imgConges_Spec} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Congés Spectacles</MDBCardTitle>
                                        <MDBCardText>
                                            Consultant Java
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard className="text-center">
                                    <MDBCardImage src={imgCredipar} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Credipar / PSA</MDBCardTitle>
                                        <MDBCardText>
                                            Consultant Technique
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard>
                                    <MDBCardImage src={imgIngdirect} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Ing Direct</MDBCardTitle>
                                        <MDBCardText>
                                            Delivery Manager
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                        <MDBRow className="wow fadeIn">
                            <MDBCol lg-4 md-12>
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBCardImage src={imgCapgemini} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Capgemini</MDBCardTitle>
                                        <MDBCardText>
                                            Salarié - Chef de Projet
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard className="text-center">
                                    <MDBCardImage src={imgModis} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Modis</MDBCardTitle>
                                        <MDBCardText>
                                            Salarié
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard>
                                    <MDBCardImage src={imgSanofi} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Sanofi Aventis</MDBCardTitle>
                                        <MDBCardText>
                                            CDP MOE + Développeur
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                        <MDBRow className="wow fadeIn">
                            <MDBCol lg-4 md-12>
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBCardImage src={imgCdn} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Crédit du Nord</MDBCardTitle>
                                        <MDBCardText>
                                            adjoint Directeur de Projet
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard className="text-center">
                                    <MDBCardImage src={imgIbm} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>IBM</MDBCardTitle>
                                        <MDBCardText>
                                            Développeur java
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12>
                                <MDBCard>
                                    <MDBCardImage src={imgAg2rlm} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>La Mondiale Partenaire</MDBCardTitle>
                                        <MDBCardText>
                                            Expert Powerbuilder
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                        <MDBRow className="wow fadeIn">
                            <MDBCol lg-4 md-12 className="mb-8">
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBCardImage src={imgServier} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Laboratoires Servier</MDBCardTitle>
                                        <MDBCardText>
                                            Consultant Powerbuilder
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12 className="mb-8">
                                <MDBCard className="text-center">
                                    <MDBCardImage src={imgLaposte} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>La Poste</MDBCardTitle>
                                        <MDBCardText>
                                            Consultant Powerbuilder
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-4 md-12 className="mb-8">
                                <MDBCard>
                                    <MDBCardImage src={imgBouygues} className="mx-auto" waves />
                                    <MDBCardBody>
                                        <MDBCardTitle>Bouygues Offshore</MDBCardTitle>
                                        <MDBCardText>
                                            Développeur Powerbuilder
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                        <MDBRow>
                            <MDBCol lg-6 md-12 className="mb-8">
                                <MDBCard style={{ width: "18rem"}}>
                                    <MDBCardBody>
                                        <MDBCardText className="text-center">
                                                <a title="PRESTATAIRE ProgOnline"
                                                   href="https://www.progonline.com/freelance-hibernate-linux-eclipse-websphere-progonline-actusoft.html">
                                                    <img alt="PRESTATAIRE ProgOnline"
                                                         src="https://www.progonline.com/images/certif.php?nickname=actusoft"
                                                         border="0"/><br/>Prestataire ProgOnline</a>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol lg-6 md-12 className="mb-8">
                                <MDBAnimation type="jello">
                                    <MDBCard style={{ width: "18rem"}}>
                                        <MDBCardTitle>CV</MDBCardTitle>
                                        <MDBCardBody>
                                            <MDBCardText className="text-center">
                                                <a href={cvPdf}><i className="fas fa-file-pdf fa-4x red-text shadow-box-example z-depth-3" /></a>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBAnimation>
                            </MDBCol>
                        </MDBRow>
                        <hr className="my-5" />
                    </section>
                </MDBContainer>
            </main>
            <footer>
                <ACDJFooter/>
            </footer>
        </div>
    );
  }
}

export default MissionsPage;
