import React from 'react';
import { MDBContainer, MDBMask, MDBView } from 'mdbreact';
import WelcomeImage from '../assets/img/welcome.jpg';
import HomePageSection1 from '../components/HomePageSection1';
import HomePageSection2 from "../components/HomePageSection2";
import HomePageSection3 from "../components/HomePageSection3";
import ACDJNavbar from "../components/ACDJNavbar";
import ACDJFooter from "../components/ACDJFooter";
import '../assets/css/HomePage.css';

const HomePage = () => {
    return (
        <div>
          <header>
            <ACDJNavbar homepage={true} />
          </header>
          <main>
            <div className='flyout'>
              <MDBView  src={WelcomeImage}>
                <MDBMask
                    overlay="black-strong"
                    pattern={8}
                    className="flex-center flex-column text-white text-center">
                  <MDBContainer>
                    <div className="row wow fadeIn">

                      <div className="col-md-6 mb-4 white-text text-md-left">
                          <h3 className="display-2">
                          <div className="font-weight-bold acdj-home-title"><span style={{color: 'orange'}}>A</span>ctusoft</div>
                          </h3>
                        <p className="mb-3">
                            <h3>
                              <span style={{color: '#00C851'}}>FREELANCE</span>
                            </h3>
                        </p>
                          <hr className="hr-light"/>
                        <p className="d-none d-md-block">
                          <strong>
                            Chef de Projet MOE, Développeur Sénior Java/JEE Full Stack
                          </strong>
                        </p>
                      </div>
                    </div>
                  </MDBContainer>
                </MDBMask>
              </MDBView>

              <MDBContainer className="my-5">
                  <h4 className="h4 text-center mb-5">
                    Mes compétences, à votre service
                  </h4>
                  <HomePageSection1/>
                  <hr className="my-5"/>
                  <HomePageSection2/>
                  <hr className="my-5"/>
                  <HomePageSection3/>
              </MDBContainer>
            </div>
          </main>
          <footer>
            <ACDJFooter/>
          </footer>
        </div>
    );
}

export default HomePage;
